import myZoneServiceProvider from '@/api/serviceProviders/my-zone'
import { useToNumber } from '@vueuse/core'
import { DescribeModuleResponse,
    DescribeModuleResponseData,
    ExportModuleCsvRequest,
    GetAvailableModulesForRoleResponse,
    GetAvailableModulesForRoleResponseData,
    GetClientPermissionsResponse,
    GetClientPermissionsResponseData,
    GetModuleActionsForRoleResponse,
    GetModuleActionsForRoleResponseData,
    GetModuleSelectFieldsForRoleResponse,
    GetModuleSelectFieldsForRoleResponseData,
    GetModuleFieldsForRoleResponse,
    GetModuleFieldsForRoleResponseData,
    GetRelatedDataResponse,
    GetRelatedDataResponseData,
    GetRelatedIndexResponse,
    GetRelatedIndexResponseData,
    GetRelatedModulesResponse,
    GetRelatedModulesResponseData,
    GetFieldsForMultipleModulesResponseData,
    GetFieldsForMultipleModulesResponse,
    GetModuleFieldsForUserResponseData,
    GetSessionsListResponseData,
    GetSessionsList } from '@/types/api/module/moduleResponses'
import { useAppStore } from '@/store/app'
import { AxiosRequestConfig } from 'axios'
import { MetaField } from '@/types/api/filter/metafieldTypes'
/**
 * Get the module description for a given module id.
 * @param module
 * @returns DescribeModuleResponseData
 */
export const describeModule = async (module: string|number): Promise<boolean | DescribeModuleResponseData> => {
    const appStore = useAppStore()

    const moduleLocalStorageKey = 'module-' + module
    const moduleTimestampLocalStorageKey = 'module-' + module + '-timestamp'

    const forceRetrieveDescribeData = async () => {
        response = await myZoneServiceProvider.get('modules/' + module + '/describe/get') as DescribeModuleResponse

        return storeDescribeToLocalStorage(response)
    }

    const storeDescribeToLocalStorage = async (response: DescribeModuleResponse) => {
        if (!response) return false

        const data = response.data.data

        localStorage.setItem(moduleLocalStorageKey, JSON.stringify(data))
        localStorage.setItem(moduleTimestampLocalStorageKey, response.headers['x-keska'])

        data.meta_fields.forEach((field: MetaField) => {
            if (!['relation', 'multi_relation', 'lazy_select', 'multi_lazy_select'].includes(field.type)) {
                useAppStore().optionsLoaded.set(String(field.id), field.options)
            }
        })

        appStore.removeModuleLoading(module)

        return response.data.data
    }

    appStore.addModuleLoading(module)
    let response = await myZoneServiceProvider.get('modules/' + module + '/describe') as DescribeModuleResponse

    if (response?.data?.data) {
        return storeDescribeToLocalStorage(response)
    } else {
        appStore.removeModuleLoading(module)
    }

    if (!localStorage.getItem(moduleLocalStorageKey)) {
        return await forceRetrieveDescribeData()
    } else {
        const currentModuleCachedTimestamp = localStorage.getItem(moduleTimestampLocalStorageKey)

        if (response?.headers['x-keska'] !== currentModuleCachedTimestamp) {
            return await forceRetrieveDescribeData()
        }

        appStore.removeModuleLoading(module)

        return JSON.parse(localStorage.getItem(moduleLocalStorageKey) ?? '{}')
    }
}

/**
 * Get the list of modules available for a given role.
 * @param module
 * @param roleId
 * @returns GetAvailableModulesForRoleResponseData
 */
export const getAvailableModulesForRole = async (module: string, roleId?: number|null): Promise <GetAvailableModulesForRoleResponseData> => {
    const appStore = useAppStore()

    appStore.addRolesLoading(`modules-for-role-${roleId}`)
    const response = useToNumber(roleId)
        ? await myZoneServiceProvider.get(module + '/modules/' + roleId) as GetAvailableModulesForRoleResponse
        : await myZoneServiceProvider.get(module + '/modules') as GetAvailableModulesForRoleResponse

    appStore.removeRolesLoading(`modules-for-role-${roleId}`)

    return response.data.data
}

/**
 * Get list of module actions for role
 * @param module
 * @param moduleId
 * @param roleId
 * @returns GetModuleActionsForRoleResponseData
 */
export const getModuleActionsForRole = async (module: string, moduleId: number, roleId?: number|null): Promise <GetModuleActionsForRoleResponseData> => {
    const response = useToNumber(roleId)
        ? await myZoneServiceProvider.get(module + '/modules/actions/' + moduleId + '/' + roleId) as GetModuleActionsForRoleResponse
        : await myZoneServiceProvider.get(module + '/modules/actions/' + moduleId) as GetModuleActionsForRoleResponse

    return response.data.data
}

/**
 * Get list of module fields for role
 * @param module
 * @param moduleId
 * @param roleId
 * @returns GetModuleFieldsForRoleResponseData
 */
export const getModuleFieldsForRole = async (module: string, moduleId: number, roleId?: number|null): Promise <GetModuleFieldsForRoleResponseData> => {
    const response = useToNumber(roleId)
        ? await myZoneServiceProvider.get(module + '/modules/fields/' + moduleId + '/' + roleId) as GetModuleFieldsForRoleResponse
        : await myZoneServiceProvider.get(module + '/modules/fields/' + moduleId) as GetModuleFieldsForRoleResponse

    return response.data.data
}

export const getModuleSelectFieldsForRole = async (module: string, moduleId: number, roleId?: number|null): Promise <GetModuleSelectFieldsForRoleResponseData> => {
    const response = useToNumber(roleId)
        ? await myZoneServiceProvider.get(module + '/modules/select-options/' + moduleId + '/' + roleId) as GetModuleSelectFieldsForRoleResponse
        : await myZoneServiceProvider.get(module + '/modules/select-options/' + moduleId) as GetModuleSelectFieldsForRoleResponse

    return response.data.data
}

export const getClientPermissions = async (id: number): Promise <GetClientPermissionsResponseData> => {
    const response = await myZoneServiceProvider.get('clients/' + id + '/permissions') as GetClientPermissionsResponse

    return response.data.data
}

export const getNextOrPrevModule = async (module: string, id: number, direction?: string) => {
    const response = await myZoneServiceProvider.get('modules/' + module + '/' + id + '/' + direction)

    return response.data.data
}

/**
 * Get related data for module
 * @param module
 * @param id
 * @returns GetRelatedDataResponseData
 */
export const getRelatedDetail = async (module: string, id: number): Promise <GetRelatedDataResponseData> => {
    const appStore = useAppStore()
    let response

    appStore.addDataLoading(`related-detail-${module}-${id}`)

    try {
        response = await myZoneServiceProvider.get('modules/' + module + '/' + id) as GetRelatedDataResponse
    } finally {
        appStore.removeDataLoading(`related-detail-${module}-${id}`)
    }

    return response.data.data
}

/**
 * Get related index for module
 * @param module
 * @param filterId??
 * @param params??
 * @param withoutLoading
 * @returns GetRelatedIndexResponseData
 */
export const getRelatedIndex = async <T>(module: string|number, filterId?: number|null, params?: AxiosRequestConfig, withoutLoading = false): Promise <GetRelatedIndexResponseData<T>|boolean> => {
    const appStore = useAppStore()

    if(!withoutLoading) {
        appStore.addDataLoading(`related-index-${module}`)
    }

    const response = await myZoneServiceProvider.post(module + '/search/' + module + (filterId ? '/' + filterId : ''), params) as GetRelatedIndexResponse<T>

    if(!withoutLoading) {
        appStore.removeDataLoading(`related-index-${module}`)
    }

    if (response) return response.data.data

    return false
}

/**
 * Get related modules for module
 * @param module
 * @param withSelf
 * @returns GetRelatedModulesResponseData
 */
export const getRelatedModules = async (module: string|number, withSelf = false): Promise <GetRelatedModulesResponseData> => {
    const appStore = useAppStore()

    appStore.addModuleLoading(`related-module-${module}`)

    const response = await myZoneServiceProvider.get('modules/' + module + '/filterable' + (withSelf ? '/1': '')) as GetRelatedModulesResponse

    appStore.removeModuleLoading(`related-module-${module}`)

    return response.data.data
}

export const exportModuleCsv = async (moduleName: string, filterId?: string|number, params?: AxiosRequestConfig, url?: string): Promise <ExportModuleCsvRequest> => {
    const requestUrl = url ? (url + '/' + (filterId !== undefined ? filterId : '')) : (moduleName + '/export/' + moduleName + '/' + (filterId !== undefined ? filterId : ''))

    return await myZoneServiceProvider.post(requestUrl, params) as ExportModuleCsvRequest
}

export const getFieldsForMultipleModules = async (modules: Array<string|number>): Promise <GetFieldsForMultipleModulesResponseData> => {
    const response = await myZoneServiceProvider.post('users/modules/modules-fields', {
        module_ids: modules,
    }) as GetFieldsForMultipleModulesResponse

    return response.data.data
}

export const getModuleFieldsForUser = async (module: string|number): Promise<GetModuleFieldsForUserResponseData | null> => {
    const response =  await myZoneServiceProvider.post('users/modules/modules-fields', {
        module_ids: [module],
    }) as GetFieldsForMultipleModulesResponse

    return response.data.data
}

export const getSessionsList = async (module: string, id: number): Promise <GetSessionsListResponseData> => {
    const appStore = useAppStore()
    let response

    appStore.addDataLoading(`sessions-list-${module}-${id}`)

    try {
        response = await myZoneServiceProvider.get(module + '/sessions/list/' + id) as GetSessionsList
    } finally {
        appStore.removeDataLoading(`sessions-list-${module}-${id}`)
    }

    return response.data.data
}

export const endSession = async (module: string, id: number, sessionId: string): Promise <GetSessionsListResponseData> => {
    const appStore = useAppStore()
    let response

    appStore.addDataLoading(`sessions-end-${module}-${id}`)

    try {
        response = await myZoneServiceProvider.post(module + '/sessions/end/' + id + '/' + sessionId) as GetSessionsList
    } finally {
        appStore.removeDataLoading(`sessions-end-${module}-${id}`)
    }

    return response.data.data
}
